import React from 'react';

import bigcomm from './B1gC0mM.svg' //'./bigcomm-qr.png'
import akeneo from './Ak3nE0.svg' //'./akeneo-qr.png'
import klaviyo from './K1aV1y0.svg' //'./klaviyo-qr.png'
import matter from './M4tT3r.svg' //'./matter-qr.png'
import starshipit from './St4R5h1p.svg' //'./starshipit-qr.png'
import yotpo from './Y0tPo.svg' //'./yotpo-qr.png'
import algolia from './Alg0l1a.svg' //'./algolia-qr.png'
import gorgias from './G0rg1as.svg' //'./gorgias-qr.png'

function QRs() {
  return (
    <div style={{textAlign: 'center'}}>
        <h1>QR List</h1>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
            <div style={{margin: '100px 0'}}>
                <img src={bigcomm} alt="BigCommerce" />
                {/* <h6>BigCommerce</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={akeneo} alt="Akeneo" />
                {/* <h6>Akeneo</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={klaviyo} alt="Klaviyo" />
                {/* <h6>Klaviyo</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={matter} alt="Matter" />
                {/* <h6>Matter</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={starshipit} alt="StarShipIt" />
                {/* <h6>StarShipIt</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={yotpo} alt="Yotpo" />
                {/* <h6>Yotpo</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={algolia} alt="Algolia" />
                {/* <h6>Algolia</h6> */}
            </div>
            <div style={{margin: '100px 0'}}>
                <img src={gorgias} alt="Gorgias" />
                {/* <h6>Gorgias</h6> */}
            </div>
        </div>
    </div>
  );
}

export default QRs;