import React from 'react'

// Alphabetical order please

import Rules from './rules'
import HideRules from './hideRules'

function Icon(props) {
  switch (props.symbol) {
    case 'rules':
      return <Rules />
    case 'hideRules':
      return <HideRules />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
