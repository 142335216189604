import { useState } from "react";
import Scanner from "../scanner/scanner";
import qrIcon from "../../qr-icon.svg";

const importAll = (r) => {
    const images = {};
    const names = r.keys().map(name => name.replace("./",""));
    const outputs = r.keys().map(r);
    names.map((name, i) => {
        images[name] = outputs[i];
        return true;
    });
    return images;
}
  
const images = importAll(require.context('./logos/', false, /\.(png|jpe?g|svg)$/));

function Stands({ captured, setCaptured, stands }) {
    const [scan, setScan] = useState(false);
    
    const standCodes = stands.map(stand => stand.qrCode);

    const setValue = (value) => {
        if (standCodes.indexOf(value) > -1 && captured.indexOf(value) === -1) {
            const updateCaptured = [...captured];
            updateCaptured.push(value);
            setCaptured(updateCaptured);
        }
    }

    return (
        <>
            <div className="stands">
                {stands.map((stand, standIndex) => (
                    <div key={standIndex} className={`stand${(captured.indexOf(stand.qrCode) > -1) ? ' captured' : ''}`} onClick={() => setScan(true)}>
                        <span>Stand {stand.stand}</span>
                        <img src={images[stand.logo]} alt={stand.name} />
                        <span className="qrcode">
                            <img src={qrIcon} alt='Scan QR' />
                        </span>
                    </div>
                ))}
            </div>
            <Scanner show={scan} control={setScan} capture={setValue} />
        </>
    )
}

export default Stands