import QrScanner from 'qr-scanner';
import { useCallback, useEffect } from 'react';

function Scanner({ show, control, capture }) {

    const closeWindow = useCallback(() => {
        control(false);
    }, [control]);

    const captureValue = useCallback((result) => {
        capture(result);
        closeWindow();
    }, [capture, closeWindow]);

    useEffect(() => {
        const viewport = document.getElementById('viewport');
        if (typeof window !== 'undefined' && viewport && show) {
            const scanner = new QrScanner(viewport, result => captureValue(result));
            scanner.start();
        }
    }, [show, captureValue]);

    return (
        <div className={`scannerWrap${show ? ' showScanner' : ''}`}>
            <span className='close' onClick={() => closeWindow()}>Close</span>
            <span>Scan QR code at Stand</span>
            {show && (
                <div className="scanner">
                    <video id="viewport"></video>
                </div>
            )}
        </div>
    
    )
}

export default Scanner;