import React, { useState } from 'react';
import axios from 'axios';
import {validateEmail} from '../helpers/general';
import { setStorage } from '../helpers/general';

const validForm = data => {
    if (!data.fullname) {
        toggleShake('fullname');
        return 'Full Name is required.';
    }
    if (!data.company) {
        toggleShake('company');
        return 'Company is required.';
    }
    if (!data.email) {
        toggleShake('email');
        return 'Email is required.';
    }
    if (!validateEmail(data.email)) {
        toggleShake('email');
        return 'Your email address is invalid.';
    }
};

const toggleShake = el => {
    document.getElementById(el).classList.add('shake');
    setTimeout(() => {
        document.getElementById(el).classList.remove('shake');
    }, 1000);
}

function Form({ progressStep, hubspot }) {

    const [values, setValues] = useState({
        fullname: '',
        company: '',
        email: '',
    });

    const [message, setMessage] = useState({
        error: '',
        success: '',
        dialogSuccess: '',
        dialogError: ''
    });

    const onHandleChange = (id, value) => {
        let tempValues = Object.assign({}, values);
        tempValues[id] = value;
        setValues(tempValues);
    };
    
    const submitRegoForm = () => {
        const validationMsg = validForm(values);
        if (validationMsg) {
            setMessage({ error: validationMsg });
            return;
        }
        setStorage('user', JSON.stringify(values), true);
        progressStep('collect');

        const userData = values;
        const contactEndpoint = `contact/createOrUpdate/email/${userData.email}/`
        const listEndpoint = `lists/${hubspot}/add`;

        const contactData = {
            properties: [
                {property: 'fullname', value: userData.fullname},
                {property: 'company', value: userData.company},
            ]
        };

        axios.post(`/.netlify/functions/hubspot?endpoint=${btoa(contactEndpoint)}`, contactData).then(response => {
            if (response.data && 'vid' in response.data) {
                const listData = {
                    vids: [response.data.vid]
                };
                
                axios.post(`/.netlify/functions/hubspot?endpoint=${btoa(listEndpoint)}`, listData);
            }
        });
    }

    return (
        <div className='form'>
            <div>
                <input type="text" id="fullname" placeholder='Full Name*' value={values['fullname']} onChange={e => onHandleChange(e.target.id, e.target.value)} />
            </div>
            <div>
                <input type="text" id="company" placeholder='Company*' value={values['company']} onChange={e => onHandleChange(e.target.id, e.target.value)} />
            </div>
            <div>
                <input type="email" id="email" placeholder='Email*' value={values['email']} onChange={e => onHandleChange(e.target.id, e.target.value)} />
            </div>
            {message && message.error && (
                <div className='form-error'>{message.error}</div>
            )}
            <div className='submitWrapper grow'>
                <input type="button" value="Start the game" onClick={() => submitRegoForm()} />
            </div>
        </div>
    )
}

export default Form;