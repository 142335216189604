import { useEffect, useMemo, useState } from 'react';
import Form from '../form/form';
import Rules from '../rules/rules';
import Letters from '../letters/letters';
import Stands from '../stands/stands';

import { getStorage, setStorage } from '../helpers/general';

const config = require('../../config.json');
const stands = config.stands;
const word = config.word;
const hubspotListId = config.hubspotListId;

function Control() {
    
    const [step, setStep] = useState('form');
    const user = getStorage('user');
    const isRegistered = user ? true : false;
    const [captured, setCaptured] = useState([]);

    useMemo(() => {
        if (getStorage('stands')) {
            setCaptured(JSON.parse(getStorage('stands')));
        }
    }, []);

    useEffect(() => {
        setStorage('stands', JSON.stringify(captured));
    }, [captured]);

    return (
        <>
            {step === 'form' && !isRegistered && (
                <Form progressStep={setStep} hubspot={hubspotListId} />
            )}
            {isRegistered && captured.length < 5 && (
                <>  
                    <Rules />
                    <Letters progressStep={setStep} captured={captured} />
                    <Stands captured={captured} setCaptured={setCaptured} stands={stands} />
                </>
            )}
            {isRegistered && captured && captured.length >= 5 && (
                <>
                    <Rules />
                    <Letters progressStep={setStep} captured={false} word={word} hubspot={hubspotListId} />
                </>
            )}
        </>
    )
}

export default Control