import Control from './components/control/control';
import logo from './logo.svg';
// import logo from './logo.png';
import mLogo from './matter-logo.svg';
import QRs from './qrs/index'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='qrs' element={<QRs />} />
      </Routes>
    </Router>
  );
}

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
      </header>
      <main className='App-contain'>
        <Control />
      </main>
      <footer className='App-footer'>
        <a href="https://matterdesign.com.au" target='_blank' rel="noreferrer">
          <img src={mLogo} alt="Matter Design" />
          <span>Made by Matter.</span>
        </a>
      </footer>
    </div>
  )
}

export default App;
