import Guess from '../guess/guess';

function Letters({ captured, word, hubspot }) {
    
    const shuffle = arr => arr
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1]);

    const wordArray = word ? word.split('') : false;
    const initialShuffle = word ? shuffle(wordArray) : false;
    const initialShuffleString = initialShuffle ? initialShuffle.join('') : false;
    let shuffledWord = false;

    if (word && word !== initialShuffleString) {
        shuffledWord = initialShuffle;
    } else if (word && word === initialShuffleString) {
        shuffledWord = shuffle(wordArray);
    }

    return (
        <>
        <div className={`letters ${word ? 'shuffledWord': ''}`}>
            <span className={`letter letter1${captured.length >= 1 ? ' found' :''}`}>
                {shuffledWord ? shuffledWord[0] : ''}
            </span>
            <span className={`letter letter2${captured.length >= 2 ? ' found' : ''}`}>
                {shuffledWord ? shuffledWord[1] : ''}
            </span>
            <span className={`letter letter3${captured.length >= 3 ? ' found' : ''}`}>
                {shuffledWord ? shuffledWord[2] : ''}
            </span>
            <span className={`letter letter4${captured.length >= 4 ? ' found' : ''}`}>
                {shuffledWord ? shuffledWord[3] : ''}
            </span>
            <span className={`letter letter5${captured.length >= 5 ? ' found' : ''}`}>
                {shuffledWord ? shuffledWord[4] : ''}
            </span>
        </div>
        
        {word && (
            <Guess word={word} hubspot={hubspot} />
        )};
        </>
    )
}

export default Letters