import React from 'react'

const HideRules = () => (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.04797 2.01601H8.06389V4.03193H6.04797V2.01601Z" fill="#FFC700"/>
        <path d="M2.01599 6.048H4.03191V8.06392H2.01599V6.048Z" fill="#FFC700"/>
        <path d="M0 8.064H2.01592V10.0799H0V8.064Z" fill="#FFC700"/>
        <path d="M8.06396 0H10.0799V2.01592H8.06396V0Z" fill="#FFC700"/>
        <path d="M2.01599 2.01601H4.03191V4.03193H2.01599V2.01601Z" fill="#FFC700"/>
        <path d="M4.03198 4.032H6.0479V6.04792H4.03198V4.032Z" fill="#FFC700"/>
        <path d="M6.04797 6.048H8.06389V8.06392H6.04797V6.048Z" fill="#FFC700"/>
        <path d="M8.06396 8.064H10.0799V10.0799H8.06396V8.064Z" fill="#FFC700"/>
        <path d="M0 0H2.01592V2.01592H0V0Z" fill="#FFC700"/>
    </svg>

)

export default HideRules