import { useMemo, useState } from 'react';
import axios from 'axios';
import { getStorage, setStorage } from '../helpers/general';

function Guess({ word, hubspot }) {

    const [message, setMessage] = useState(false);
    const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const checkWord = (guessWord) => {
        if (guessWord) {
            if (guessWord === word) {
                setIsFieldsDisabled(true);
                setMessage('Congratulations, you got it!');
            } else {
                toggleShake('guessContainer');
                setMessage('That’s not it, try again!');
                clearLetters();
            }
        }
    };

    const toggleShake = el => {
        document.getElementById(el).classList.add('shake');
        setTimeout(() => {
            document.getElementById(el).classList.remove('shake');
        }, 1000);
    }

    const clearLetters = () => {
        const inputsList = document.querySelectorAll('input');
        
        [].forEach.call(inputsList, function(input) {
            input.value = '';
        });

        inputsList[0].focus();
    }

    const goToNextInput = () => {
        const inputsList = document.querySelectorAll('input');

        for(const [i, inputElement] of inputsList.entries()) {
            const inputsListArray = Array.from(inputsList);
            const allInputsFilled = inputsListArray.every((input) => input.value.length === 1);

            if (inputElement.value.length === 1 && i !== 4) {
                inputsList[i+1].focus();
            }

            if (inputElement.value.length === 1 && i === 4) {
                inputElement.parentElement.nextElementSibling.focus();
            }
            

            if (allInputsFilled) {
                const guessArray = Object.keys(inputsList).map((input, i) => inputsList[input].value);
                const guessWord = guessArray.join('').toUpperCase();
                checkWord(guessWord);
            } else {
                setMessage('');
            }
            
        }
    }

    const checkInput = (e) => {
        if ((e.key === 'Delete' || e.key === 'Backspace') && e.target.value.length === 0) {
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    }

    const enterDraw = () => {
        const user = getStorage('user');
        if (user) {
            const userData = JSON.parse(user);
            const contactEndpoint = `contact/createOrUpdate/email/${userData.email}/`

            const contactData = {
                properties: [
                    {property: 'fullname', value: userData.fullname},
                    {property: 'company', value: userData.company},
                    {property: 'or22_entrant', value: true}
                ]
            };

            axios.post(`/.netlify/functions/hubspot?endpoint=${btoa(contactEndpoint)}`, contactData).then(response => {
                if (response.data && 'vid' in response.data) {
                    setSubmitted(true);
                    setStorage('submitted', true);
                } else {
                    // TODO: Error occurred - try again message to user
                }
            });
        }
    }

    useMemo(() => {
        if (getStorage('submitted')) {
            setSubmitted(true);
        }
    }, []);
    
    return (
        <>
        <div className="letters guess" id="guessContainer">
            <input className="letter letter1" type="text" maxLength="1" disabled={isFieldsDisabled} 
                onChange={() => goToNextInput()} 
                onKeyDown ={((e) => checkInput(e))}
            />
            <input className="letter letter2" type="text" maxLength="1" disabled={isFieldsDisabled} 
                onChange={() => goToNextInput()} 
                onKeyDown ={((e) => checkInput(e))}
            />
            <input className="letter letter3" type="text" maxLength="1" disabled={isFieldsDisabled} 
                onChange={() => goToNextInput()} 
                onKeyDown ={((e) => checkInput(e))}
            />
            <input className="letter letter4" type="text" maxLength="1" disabled={isFieldsDisabled} 
                onChange={() => goToNextInput()} 
                onKeyDown ={((e) => checkInput(e))}
            />
            <input className="letter letter5" type="text" maxLength="1" disabled={isFieldsDisabled} 
                onChange={() => goToNextInput()} 
                onKeyDown ={((e) => checkInput(e))}
            />
        </div>

        <div className="messages font--space-grotesk-bold">
            {message}
        </div>

        {(isFieldsDisabled && !submitted) && (
            <div className="buttonWrapper grow">
                <button className="button" onClick={() => enterDraw()}>Enter Prize Draw</button>
                {/* <a className="button" href="https://matterdesign.com.au">Enter Prize Draw</a> */}
            </div>
        )}

        {submitted && (
            <div className="messages font--space-grotesk-bold">
                You're entered into the draw to win!
            </div>
        )}
        </>
    )
}

export default Guess