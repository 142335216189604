import { useEffect, useState } from 'react';
import styles from './rules.module.css';
import Icon from '../icons/icon';

function Rules() {
    const [rulesSymbol, setRulesSymbol] = useState('rules');
    const [rulesTitle, setrulesTitle] = useState('How to play?');
    const [rulesActive, setRulesActive] = useState(false);

    useEffect(() => {
        setRulesActive(rulesActive);
        setRulesSymbol(rulesSymbol);
        setrulesTitle(rulesTitle);
    }, [rulesSymbol, rulesTitle, rulesActive]);

    const toggleRules = () => {
        const active = rulesActive ? false : true;
        const title = rulesActive ? 'How to play?' :  'hide rules';
        const symbol = rulesActive ?  'rules': 'hideRules';
        
        setRulesActive(active);
        setRulesSymbol(symbol);
        setrulesTitle(title);
    };

    return (
        <>
            <div className={`${rulesActive ? styles.howToPlayActive : styles.howToPlay}`}>
                <Icon symbol={rulesSymbol}/>
                <span className='font--space-grotesk-bold' onClick={() => toggleRules()}>{rulesTitle}</span>
                
                <div className={`${styles.rulesContent} font--space-grotesk-medium`}>
                    <p>Collect 5 letters from any of these stands to play</p>
                    <p>Tap on logo tile and simply scan the QR code at their stand to get the letter</p>
                    <p>Solve the puzzle and enter to win!</p>
                    <p><a target="_blank" href="https://matterdesign.com.au" rel="noreferrer" className="font--space-grotesk-bold">read terms</a></p>
                </div>
            </div>
        </>
    )
}

export default Rules